import { fetchConToken } from "../../helpers/fetch";
import Swal from "sweetalert2";

//==============CRUD USUARIOS=================

export const onCrearDetalleImpuesto = (
    ciudad, nit, impuesto, enero,
    febrero, marzo, abril, mayo, junio, julio,
    agosto, septiembre, octubre, noviembre, diciembre,
    natural, juridico, contribuyente) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('/api/dashboard/detalleimpuesto',
                {
                    id_ciudad:ciudad,
                    id_impuesto:impuesto,
                    id_nit:nit,
                    enero,
                    febrero,
                    marzo,
                    abril,
                    mayo,
                    junio,
                    julio,
                    agosto,
                    septiembre,
                    octubre,
                    noviembre,
                    diciembre,
                    natural,
                    juridico,
                    contribuyente

                }, 'POST');
            const body = await resp.json();

            if (resp.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Se ha creado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.impuestos;


            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const onEditarDetalleImpuesto = (
    id, enero, febrero, marzo, abril,
    mayo, junio, julio, agosto, septiembre,
    octubre, noviembre, diciembre, estado
) => {
    return async (dispatch) => {
        try {
            ;
            const resp = await fetchConToken('/api/dashboard/detalleimpuesto',
            {    id, enero, febrero, marzo, abril,
                mayo, junio, julio, agosto, septiembre,
                octubre, noviembre, diciembre, estado },
                 'PUT');
            const body = await resp.json();

            if (resp.ok) {
               await  Swal.fire({
                    icon: "success",
                    title: "Se ha editado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.impuestos;


            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const serviceEliminarDetalleImpuesto = (id) => {
    return async (dispatch) => {
        try {
            ;

            const resp = await fetchConToken('/api/dashboard/detalleimpuesto', { id }, 'DELETE');
            const body = await resp.json();
            ;

            if (resp.ok) {

                Swal.fire({
                    icon: "success",
                    title: "Se ha inhabilitado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.impuestos;

            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

//==============CRUD FIN USUARIOS==============

export const serviceCargarDataImpuesto = (idImpuesto) => {
    return async (dispatch) => {
        try {
            ;

            const resp = await fetchConToken('/api/dashboard/detalleimpuesto/data', { idImpuesto }, 'POST');
            const body = await resp.json();
            ;

            if (resp.ok) {
                return body.impuestos;

            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}
