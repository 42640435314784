import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle'

import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

import { MainApp } from './MainApp';

ReactDOM.render(
    <MainApp />,
  document.getElementById('root')
);