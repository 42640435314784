import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { customStyles } from '../../../../helpers/modal_styles';
import './NameImpuestoCrearModal.css'
import { useDispatch } from "react-redux";
import { onCrearNameImpuesto } from '../../../../services/nameimpuestos/nameimpuestoService';




export const NameImpuestoCrearModal = ({  state, setState , setmodalCrear, modalCrear}) => {
    const dispatch = useDispatch();
    Modal.setAppElement('#root');

    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        const initialState = {
            impuesto: "",
        }
        setFormValues(initialState);


    }, [])


    //FUNCTION========================
    const closeModal = () => {
        setmodalCrear(false);
    }

    const crear = async(e) => {
        e.preventDefault();
          ;
        const resp = await dispatch(onCrearNameImpuesto( 
            formValues.impuesto,
            ));
            ;
            if (!!resp) {
            setState([...state, resp]);
            }


        //     const newArray = updateStateForId(usuario, resp);
        //     setUsuario([...newArray]);
            closeModal();

    }

    //FIN FUNCTION========================


    return (
        <Modal
            isOpen={modalCrear}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <center><h3>Crear Impuesto</h3></center>
            <br />
            <form onSubmit={crear}>
                <div className="form-row">
                    <div className="form-grupin col-md-12">
                        <input
                            placeholder="Ingrese un nombre de impuesto"
                            type="text"
                            name="impuesto"
                            className="form-control"
                            value={formValues.ciudad}
                            onChange={(e) =>
                                setFormValues({ ...formValues, impuesto: e.target.value })
                            }
                        />
                    </div>
                </div>

                <button className="btn btncrear">Crear</button>
            </form>

        </Modal>
    )
}
