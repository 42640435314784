import React from 'react'
import Modal from 'react-modal';
import { customStyles } from '../../../../helpers/modal_styles';
import "moment/locale/es";





export const DatesModal = ({ setShowModal, showModal, register, callback }) => {
    Modal.setAppElement('#root');




    //FUNCTION========================
    const closeModal = () => {
        setShowModal(false);
        callback();
    }






    return (
        <Modal
            isOpen={showModal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <center><h3>Fechas</h3></center>
            <br />

            <div className="form-row">
                <div className="form-grupin col-md-4">
                    <h6>Enero</h6>
                    <input
                        placeholder="Enero"
                        type="date"
                        name="enero"
                        className="form-control"
                        {...register('enero')}

                    />
                </div>
                <div className="form-grupin col-md-4">
                    <h6>Febrero</h6>
                    <input
                        placeholder="Febrero"
                        type="date"
                        name="febrero"
                        className="form-control"
                        {...register('febrero')}
                    />
                </div>
                <div className="form-grupin col-md-4">
                    <h6>Marzo</h6>
                    <input
                        placeholder="Marzo"
                        type="date"
                        name="marzo"
                        className="form-control"
                        {...register('marzo')}
                    />
                </div>
            </div>

            <div className="form-row">
                <div className="form-grupin col-md-4">
                    <h6>Abril</h6>
                    <input
                        placeholder="Abril"
                        type="date"
                        name="abril"
                        className="form-control"
                        {...register('abril')}
                    />
                </div>
                <div className="form-grupin col-md-4">
                    <h6>Mayo</h6>
                    <input
                        placeholder="Mayo"
                        type="date"
                        name="mayo"
                        className="form-control"
                        {...register('mayo')}
                    />
                </div>
                <div className="form-grupin col-md-4">
                    <h6>Junio</h6>
                    <input
                        placeholder="Junio"
                        type="date"
                        name="junio"
                        className="form-control"
                        {...register('junio')}
                    />
                </div>
            </div>

            <div className="form-row">
                <div className="form-grupin col-md-4">
                    <h6>Julio</h6>
                    <input
                        placeholder="Julio"
                        type="date"
                        name="julio"
                        className="form-control"
                        {...register('julio')}
                    />
                </div>
                <div className="form-grupin col-md-4">
                    <h6>Agosto</h6>
                    <input
                        placeholder="Agosto"
                        type="date"
                        name="agosto"
                        className="form-control"
                        {...register('agosto')}
                    />
                </div>
                <div className="form-grupin col-md-4">
                    <h6>Septiembre</h6>
                    <input
                        placeholder="Septiembre"
                        type="date"
                        name="septiembre"
                        className="form-control"
                        {...register('septiembre')}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-grupin col-md-4">
                    <h6>Octubre</h6>
                    <input
                        placeholder="Octubre"
                        type="date"
                        name="octubre"
                        className="form-control"
                        {...register('octubre')}
                    />
                </div>
                <div className="form-grupin col-md-4">
                    <h6>Noviembre</h6>
                    <input
                        placeholder="Noviembre"
                        type="date"
                        name="noviembre"
                        className="form-control"
                        {...register('noviembre')}
                    />
                </div>
                <div className="form-grupin col-md-4">
                    <h6>Diciembre</h6>
                    <input
                        placeholder="Diciembre"
                        type="date"
                        name="diciembre"
                        className="form-control"
                        {...register('diciembre')}
                    />
                </div>
            </div>




            <div className='text-center'>
                <button className="btn btncrear col-lg-4" onClick={closeModal}>Guardar</button>
            </div>

        </Modal>
    )
}
