import React, { useEffect } from 'react'
import { Chart, registerables } from 'chart.js';
import Swal from 'sweetalert2';

Chart.register(...registerables);


export const UserCharts = ({ identificador, tipo = "bar" }) => {


    //PETICION HTTP USEEFFECT
    useEffect(() => {
        async function getData() {
            const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/datos/chartsuser`;
            const resp = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Content-type': 'application/json',
                        'x-token': localStorage.getItem('token')
                    },
                }
            );
            const respuesta = await resp.json();

            ;
            if (resp.ok) {
                
                crearGrafica(respuesta.charts);


            } else {
                if (respuesta.msg === undefined) {
                    Swal.fire('Error', respuesta.errors[0].msg, 'error');
                } else {
                    Swal.fire('Error', respuesta.msg, 'error');
                }
            }
        }
        getData();
    });

    const crearGrafica = (chart) => {
        ;
        console.log(chart);
        const ctx = document.getElementById(identificador);
        new Chart(ctx, {
            type: tipo,
            data: {
                labels: chart.label,
                datasets: [{
                    label: 'Usuarios',
                    data: chart.data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    xAxes: [{
                        stacked: true
                    }]

                }
            }
        });
        ;
    }


    return (
        <canvas id={identificador}></canvas>
    )
}
