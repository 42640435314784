import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";

//==============CRUD USUARIOS=================

export const onCrearUsuario = (nombrerazonsocial, responsable, email, telefono, ciudad, estado, verificado, rol_id, password) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('/api/dashboard/users', { nombrerazonsocial, responsable, email, telefono, ciudad, estado, verificado, rol_id, password }, 'POST');
            const body = await resp.json();

            if (resp.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Se ha creado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.usuario;


            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const onEditarUsuario = (id, email, ciudad, estado, rol_id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('/api/dashboard/users', { id, email, id_ciudad: ciudad, estado, rol_id }, 'PUT');
            const body = await resp.json();

            if (resp.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Se ha editado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.usuario;


            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const serviceInhabilitarUsuario = (id) => {
    return async (dispatch) => {
        try {
            ;

            const resp = await fetchConToken('/api/dashboard/users', { id }, 'DELETE');
            const body = await resp.json();
            ;

            if (resp.ok) {

                Swal.fire({
                    icon: "success",
                    title: "Se ha inhabilitado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.usuario;

            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const serviceEliminarUsuario = (id_cliente, email) => {
    return async (dispatch) => {
        try {

            const resp = await fetchConToken('/api/dashboard/users/deleteUsuario', { id_cliente, email }, 'DELETE');
            const body = await resp.json();

            if (resp.ok) {

                Swal.fire({
                    icon: "success",
                    title: "Se ha eliminado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return true;

            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

//==============CRUD FIN USUARIOS==============
