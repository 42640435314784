import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import { lenguage, optionsTableGroup, tableIcons, } from "../../structure/config-datatable";
import { useDispatch } from 'react-redux';
import { updateStateForId } from '../../../helpers/updateState';
import { FooterCard } from '../../structure/card/FooterCard';
import Swal from 'sweetalert2';
import { DetalleImpuestoEditModal } from './edit-model/DetalleImpuestoEditModal';
import Moment from "moment";
import "moment/locale/es";
import { serviceCargarDataImpuesto, serviceEliminarDetalleImpuesto } from '../../../services/detalleimpuestos/detalleImpuestoService';
import Select from 'react-select';


let impuestoList = [];

export const DatatableDetalleImpuesto = ({ impuestosNombre, cargarImpuestos, setCargaImpuestos }) => {

    const dispatch = useDispatch();
    const [modalEditar, setmodalEditar] = useState(false);
    const [edit, setEdit] = useState({})
    const [state, setState] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    // const [impuestosNombre, setImpuestosNombre] = useState([]);
    const [nit, setNit] = useState([]);



    //PETICION HTTP USEEFFECT
    // useEffect(() => {
    //     async function getData() {
    //         const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/dashboard/detalleimpuesto`;
    //         const resp = await fetch(url,
    //             {
    //                 method: "GET",
    //                 headers: {
    //                     'Content-type': 'application/json',
    //                     'x-token': localStorage.getItem('token')
    //                 },
    //             }
    //         );
    //         const respuesta = await resp.json();

    //         if (resp.ok) {
    //             setState(respuesta.impuestos);
    //             setCargaImpuestos(true);

    //         } else {
    //             if (respuesta.msg === undefined) {
    //                 Swal.fire('Error', respuesta.errors[0].msg, 'error');
    //             } else {
    //                 Swal.fire('Error', respuesta.msg, 'error');
    //             }
    //         }
    //     }
    //     getData();

    // }, [setState]);


    useEffect(() => {
        async function getData() {
            const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/datos/ciudades`;
            const resp = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Content-type': 'application/json',
                        'x-token': localStorage.getItem('token')
                    },
                }
            );
            const respuesta = await resp.json();

            if (resp.ok) {
                setCiudades(respuesta.ciudades);

            } else {
                if (respuesta.msg === undefined) {
                    Swal.fire('Error', respuesta.errors[0].msg, 'error');
                } else {
                    Swal.fire('Error', respuesta.msg, 'error');
                }
            }
        }
        getData();

    }, []);

    useEffect(() => {
        async function getData() {
            const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/datos/nits`;
            const resp = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Content-type': 'application/json',
                        'x-token': localStorage.getItem('token')
                    },
                }
            );
            const respuesta = await resp.json();

            if (resp.ok) {
                setNit(respuesta.nits);

            } else {
                if (respuesta.msg === undefined) {
                    Swal.fire('Error', respuesta.errors[0].msg, 'error');
                } else {
                    Swal.fire('Error', respuesta.msg, 'error');
                }
            }
        }
        getData();

    }, [setNit]);

    // useEffect(() => {
    //     async function getData() {
    //         const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/datos/impuestos`;
    //         const resp = await fetch(url,
    //             {
    //                 method: "GET",
    //                 headers: {
    //                     'Content-type': 'application/json',
    //                     'x-token': localStorage.getItem('token')
    //                 },
    //             }
    //         );
    //         const respuesta = await resp.json();

    //         if (resp.ok) {
    //             setImpuestosNombre(respuesta.impuestos);

    //         } else {
    //             if (respuesta.msg === undefined) {
    //                 Swal.fire('Error', respuesta.errors[0].msg, 'error');
    //             } else {
    //                 Swal.fire('Error', respuesta.msg, 'error');
    //             }
    //         }
    //     }
    //     getData();

    // }, [setImpuestosNombre]);

    useEffect(() => {
        impuestoList = [];
        impuestosNombre.forEach(element => {
            console.log(element);
            impuestoList.push({ "value": element.id, "label": element.impuesto })
        });
        console.log("IMPUESTO LISTO;:::::::", impuestoList);
    }, [impuestosNombre])


    //===========CONFIG TABLE ============================================
    const acciones = [{
        icon: () => <tableIcons.Edit style={{ color: "#FFC007" }} />,
        tooltip: 'Editar',
        onClick: (e, rowData) => { setmodalEditar(true); setEdit(state.find(user => user.id === rowData.id)) },

    },
    {
        icon: () => <tableIcons.Delete style={{ color: "#DC3444" }} />,
        tooltip: 'Eliminar',
        onClick: (e, rowData) => onEliminar(state.find(user => user.id === rowData.id))
    }]

    //CREACION DE LAS COLUMNAS QUE SE VAN A MOSTRAR
    const columnas = [
        {
            title: "Codigo",
            field: "id"
        },
        {
            title: "Ciudad",
            field: "Ciudade.ciudad",
            defaultGroupOrder: 1
        },
        {
            title: "Impuesto",
            field: "Impuesto.impuesto",
            defaultGroupOrder: 0
        },
        {
            title: "Nit",
            field: "Nit.nit",
            defaultGroupOrder: 2
        },
        {
            title: "Natural",
            field: "natural",
            render: rowData => (rowData.natural)
                ? <tableIcons.Check style={{ color: "green" }} />
                : <tableIcons.Clear style={{ color: "red" }} />
        },
        {
            title: "Juridico",
            field: "juridico",
            render: rowData => (rowData.juridico)
                ? <tableIcons.Check style={{ color: "green" }} />
                : <tableIcons.Clear style={{ color: "red" }} />
        },
        {
            title: "Contribuyente",
            field: "contribuyente",

            render: rowData => (rowData.contribuyente)
                ? <tableIcons.Check style={{ color: "green" }} />
                : <tableIcons.Clear style={{ color: "red" }} />

        },

        {
            title: "Enero",
            field: "enero",
            render: rowData =>
                rowData.enero ?
                    Moment(rowData.enero).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Febrero",
            field: "febrero",
            render: rowData =>
                rowData.febrero ?
                    Moment(rowData.febrero).format("D MMM YYYY")
                    : ""

        },
        {
            title: "Marzo",
            field: "marzo",
            render: rowData =>
                rowData.marzo ?
                    Moment(rowData.marzo).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Abril",
            field: "abril",
            render: rowData =>
                rowData.abril ?
                    Moment(rowData.abril).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Mayo",
            field: "mayo",
            render: rowData =>
                rowData.mayo ?
                    Moment(rowData.mayo).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Junio",
            field: "junio",
            render: rowData =>
                rowData.junio ?
                    Moment(rowData.junio).format("D MMM YYYY")
                    : ""

        },
        {
            title: "Julio",
            field: "julio",
            render: rowData =>
                rowData.julio ?
                    Moment(rowData.julio).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Agosto",
            field: "agosto",
            render: rowData =>
                rowData.agosto ?
                    Moment(rowData.agosto).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Septiembre",
            field: "septiembre",
            render: rowData =>
                rowData.septiembre ?
                    Moment(rowData.septiembre).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Octubre",
            field: "octubre",
            render: rowData =>
                rowData.octubre ?
                    Moment(rowData.octubre).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Noviembre",
            field: "noviembre",
            render: rowData =>
                rowData.noviembre ?
                    Moment(rowData.noviembre).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Diciembre",
            field: "diciembre",
            render: rowData =>
                rowData.diciembre ?
                    Moment(rowData.diciembre).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Estado",
            field: "estado",
            render: rowData => (rowData.estado)
                ? <tableIcons.Check style={{ color: "green" }} />
                : <tableIcons.Clear style={{ color: "red" }} />

        },
    ]
    const data = state;

    // FUNCTION ==================================
    const onEliminar = async (user) => {
        const respuesta = await dispatch(serviceEliminarDetalleImpuesto(user.id));
        ;
        const newArray = updateStateForId(state, respuesta);
        setState([...newArray]);
    }
    // FIN FUNCTION ==================================

    const onReloadData = async (e) => {
        console.log(e);
        setCargaImpuestos(false);
        const respuesta = await dispatch(serviceCargarDataImpuesto(e.value));
        setState(respuesta);
        setCargaImpuestos(true);

    }


    return (
        <>
            <div className="form-row">
                <div className=" form-grupin col-md-12">
                    <Select
                        options={impuestoList}
                        placeholder="Impuesto"
                        onChange={(e) => {
                            onReloadData(e);
                            // setFormValues({ ...formValues, impuesto: Number(e.value), labelImpuesto: e.label })
                        }}
                    />
                </div>
            </div>
            {cargarImpuestos ?
                <div className="card-body">
                    <div>
                        <MaterialTable
                            icons={tableIcons}
                            columns={columnas}
                            data={data}
                            title={""}
                            options={optionsTableGroup}
                            localization={lenguage}
                            actions={acciones}
                        // options={{
                        //     grouping: true
                        //   }}
                        // detailPanel={rowData => {
                        //     return (
                        //         <div>
                        //             <h1>{rowData.genero}</h1>
                        //         </div>)
                        // }}
                        />

                        <DetalleImpuestoEditModal setmodalEditar={setmodalEditar}
                            modalEditar={modalEditar}
                            editar={edit}
                            state={state}
                            setState={setState}
                        />

                    </div>
                </div>
                : <div class="loader-carga center"></div>

            }
            <FooterCard
                setmodalEditar={setmodalEditar}
                modalEditar={modalEditar}
                texto={"Crear Impuesto"}
                editar={edit}
                state={state}
                setState={setState}
                ciudades={ciudades}
                nit={nit}
                impuestosNombre={impuestosNombre}
                modalKey={"detalleimpuesto"} />
        </>
    )
}



