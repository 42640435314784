import { fetchConToken } from "../../helpers/fetch";
import Swal from "sweetalert2";

//==============CRUD USUARIOS=================

export const onCrearCiudad = (ciudad) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('/api/dashboard/ciudades', { ciudad }, 'POST');
            const body = await resp.json();

            if (resp.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Se ha creado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.ciudades;


            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const onEditarCiudad = (id, ciudad, estado) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('/api/dashboard/ciudades', { id, ciudad, estado }, 'PUT');
            const body = await resp.json();

            if (resp.ok) {
                Swal.fire({
                    icon: "success",
                    title: "Se ha editado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.ciudades;


            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const serviceEliminarCiudad = (id) => {
    return async (dispatch) => {
        try {
            ;

            const resp = await fetchConToken('/api/dashboard/ciudades', { id }, 'DELETE');
            const body = await resp.json();
            ;

            if (resp.ok) {

                Swal.fire({
                    icon: "success",
                    title: "Se ha inhabilitado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.ciudades;

            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

//==============CRUD FIN USUARIOS==============


export const getCiudades = async () => {


    const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/dashboard/ciudades`;
    const resp = await fetch(url,
        {
            method: "GET",
            headers: {
                'Content-type': 'application/json',
                'x-token': localStorage.getItem('token')
            },
        }
    );
    const respuesta = await resp.json();

    ;
    if (resp.ok) {

        return respuesta.ciudades;

    } else {
        if (respuesta.msg === undefined) {
            // Swal.fire('Error', respuesta.errors[0].msg, 'error');
            throw Error(respuesta.errors[0].msg);
        } else {
            throw Error(respuesta.msg);
            // Swal.fire('Error', respuesta.msg, 'error');
        }
    }


}