import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import { lenguage, optionsTable, tableIcons, } from "../../structure/config-datatable";
import Swal from 'sweetalert2';
import Moment from "moment";
import "moment/locale/es";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FooterCard } from '../../structure/card/FooterCard';




export const DatatableCompraMembresias = () => {


    const [state, setState] = useState([]);



    //PETICION HTTP USEEFFECT
    useEffect(() => {
        async function getData() {
            const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/dashboard/membresia/compras`;
            const resp = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Content-type': 'application/json',
                        'x-token': localStorage.getItem('token')
                    },
                }
            );
            ;
            const respuesta = await resp.json();

            ;
            if (resp.ok) {
                setState(respuesta.comprasMembresia);

            } else {
                if (respuesta.msg === undefined) {
                    Swal.fire('Error', respuesta.errors[0].msg, 'error');
                } else {
                    Swal.fire('Error', respuesta.msg, 'error');
                }
            }
        }
        getData();

    }, [setState]);


    ;




    //CREACION DE LAS COLUMNAS QUE SE VAN A MOSTRAR
    const columnas = [
        {
            title: "Codigo",
            field: "id"
        },
        {
            title: "Email",
            field: "email",
        },{
            title: "Razón social",
            field: "nombrerazonsocial",
        },{
            title: "Cedula",
            field: "cedula",
        },
        {
            title: "Teléfono",
            field: "telefono",
        },
        {
            title: "Tipo membresia",
            field: "tipo",
        },
        {
            title: "Fecha Vencimiento",
            field: "fecha_vencimiento",
            render: rowData =>
                rowData.fecha_vencimiento !== undefined ?
                    Moment(rowData.fecha_vencimiento).format("D MMM YYYY")
                    : ""
        }
       
    ]
    const data = state;
    
    const downloadExcel = () => {

        const filteredData = data.map(({ tableData, ...rest }) => rest);
    // Crear hoja de trabajo
    const ws = XLSX.utils.json_to_sheet(filteredData);
    // Crear libro de trabajo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Datos");
    // Generar archivo Excel
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // Guardar archivo
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, "compra-membresias.xlsx");
  };



    return (
        <>
            <div className="card-body">

                    <MaterialTable
                        icons={tableIcons}
                        columns={columnas}
                        data={data}
                        title={""}
                        options={optionsTable}
                        localization={lenguage}

                    />

                     <div className="card-footer row">
                        <div className="col-md-3">
                        <button type="button" className="btncrear btn btn-block btn-lg" onClick={downloadExcel}>Descargar</button>
                        </div>
                    </div>
                   
             

            </div>


         
        </>
    )
}



