import React from 'react'

export const Footer = () => {
    return (
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                <b>Version</b> 0.0.1
            </div>
            <strong>Copyright 2022 <a href="*">Calendapp</a>.</strong> Todos los derechos reservados.
        </footer>

    )
}
