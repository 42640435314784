import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { HeadCard } from '../../structure/card/HeadCard'
import { DatatableDetalleImpuesto } from './DatatableDetalleImpuesto'

export const DetalleImpuestoPage = () => {

    const [impuestosNombre, setImpuestosNombre] = useState([]);
    const [cargaImpuestos, setCargaImpuestos] = useState(false);


    useEffect(() => {
        async function getData() {
            const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/datos/impuestos`;
            const resp = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Content-type': 'application/json',
                        'x-token': localStorage.getItem('token')
                    },
                }
            );
            const respuesta = await resp.json();

            if (resp.ok) {
                setImpuestosNombre(respuesta.impuestos);
                setCargaImpuestos(true);

            } else {
                if (respuesta.msg === undefined) {
                    Swal.fire('Error', respuesta.errors[0].msg, 'error');
                } else {
                    Swal.fire('Error', respuesta.msg, 'error');
                }
            }
        }
        getData();

    }, [setImpuestosNombre]);


    return (
        <div className="content-wrapper">
            <HeadCard titulo="IMPUESTOS" />
            <section className="content">
                <div className="card">
                    <div className="card-header">
                    </div>

                    <DatatableDetalleImpuesto impuestosNombre={impuestosNombre} cargarImpuestos={cargaImpuestos} setCargaImpuestos={setCargaImpuestos}/>

                </div>
            </section>
        </div>
    )
}
