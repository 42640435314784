import MaterialTable from 'material-table';
import Moment from "moment";
import "moment/locale/es";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { updateStateForId } from '../../../helpers/updateState';
import { serviceEliminarNameImpuesto } from '../../../services/nameimpuestos/nameimpuestoService';
import { lenguage, optionsTable, tableIcons, } from "../../structure/config-datatable";
import { MembresiaEditModal } from './edit-model/MembresiaEditModal';





export const DatatableMembresia = () => {

    const dispatch = useDispatch();
    const [modalEditar, setmodalEditar] = useState(false);
    const [edit, setEdit] = useState({})
    const [state, setState] = useState([]);
    const [originalData, setOriginalData] = useState([]);


    //PETICION HTTP USEEFFECT
    useEffect(() => {
        async function getData() {
            const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/dashboard/membresia`;
            const resp = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Content-type': 'application/json',
                        'x-token': localStorage.getItem('token')
                    },
                }
            );
            ;
            const respuesta = await resp.json();

            ;
            if (resp.ok) {
                setState(respuesta.membresias);
                setOriginalData(respuesta.membresias);

            } else {
                if (respuesta.msg === undefined) {
                    Swal.fire('Error', respuesta.errors[0].msg, 'error');
                } else {
                    Swal.fire('Error', respuesta.msg, 'error');
                }
            }
        }
        getData();

    }, [setState]);


    ;



    //===========CONFIG TABLE ============================================
    const acciones = [{
        icon: () => <tableIcons.Edit style={{ color: "#FFC007" }} />,
        tooltip: 'Editar',
        onClick: (e, rowData) => { setmodalEditar(true); onSearchEntity(state.find(user => user.id === rowData.id)) },

    },
    {
        icon: () => <tableIcons.Delete style={{ color: "#DC3444" }} />,
        tooltip: 'Eliminar',
        onClick: (e, rowData) => onEliminar(state.find(user => user.id === rowData.id))
    }]

    //CREACION DE LAS COLUMNAS QUE SE VAN A MOSTRAR
    const columnas = [
        {
            title: "Codigo",
            field: "Membresia.id"
        },
        {
            title: "Email",
            field: "email",
        },
        {
            title: "Fecha Vencimiento",
            field: "Membresia.fecha_vencimiento",
            render: rowData =>
                rowData.Membresia.fecha_vencimiento !== undefined ?
                    Moment(rowData.Membresia.fecha_vencimiento).format("D MMM YYYY")
                    : ""
        },
        {
            title: "Tipo Membresia",
            field: "Membresia.tipomembresia.membresia",
        },
    ]
    const data = state;
    ;


    // FUNCTION ==================================
    const onEliminar = async (user) => {
        ;
        const respuesta = await dispatch(serviceEliminarNameImpuesto(user.id));
        ;
        const newArray = updateStateForId(state, respuesta);
        ;
        setState([...newArray]);
    }
    // FIN FUNCTION ==================================
    const onSearchEntity = (entidad) => {
        console.log("EDITAR ENTIDAD DATATABLE", entidad);
        setEdit({
            id: entidad.Membresia.id,
            email: entidad.email,
            fechaVencimiento: entidad.Membresia.fecha_vencimiento,
            idMembresia: entidad.Membresia.tipomembresia.id,
            membresia: entidad.Membresia.tipomembresia.membresia
        })
    }

    const filterByType = (type) => {
        setState(originalData.filter(row =>
            Moment(row.Membresia.fecha_vencimiento).isSame(new Date(), 'month') &&
            row.Membresia.tipomembresia.membresia === type
        ));

    };
    const deleteFilter = () => {
        setState(originalData);

    }

    return (
        <>
            <div className="card-body">
                <div>

                    <div className="mb-3">
                        <button className="btn btn-success mr-2" onClick={() => filterByType('Premium')}>
                            Próximos a Vencer Premium
                        </button>
                        <button className="btn btn-primary mr-2" onClick={() => { deleteFilter(); filterByType('General'); }}>
                            Próximos a Vencer General
                        </button>
                        <button className="btn btn-warning mr-2" onClick={() => filterByType('Gratis')}>
                            Próximos a Vencer Gratis
                        </button>
                        <button className="btn btn-secondary mr-2" onClick={() => deleteFilter()}>
                            Borrar Filtro
                        </button>
                    </div>

                    <MaterialTable
                        icons={tableIcons}
                        columns={columnas}
                        data={data}
                        title={""}
                        options={optionsTable}
                        localization={lenguage}
                        actions={acciones}
                    />
                    <MembresiaEditModal setmodalEditar={setmodalEditar}
                        modalEditar={modalEditar}
                        editar={edit}
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
        </>
    )
}



